import React, { useState } from 'react';
import { Card, CardHeader, Button, Divider, Box, Table, TableHead, TableRow, TableCell, TableBody, Typography, Chip, TablePagination, ChipProps, SxProps, OutlinedInput, InputAdornment, Stack } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { OrdersType } from '../../../interfaces/Orders';
import { CustomFetch } from '../../../utils/Fetch/Fetch';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';

const API_URL = process.env.REACT_APP_API_URL;

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
    Pending: { label: 'Pending', color: 'warning' },
    Unshipped: { label: 'Unshipped', color: 'default' },
    PartiallyShipped: { label: 'PartiallyShipped', color: 'default' },
    Shipped: { label: 'Shipped', color: 'success' },
    Canceled: { label: 'Canceled', color: 'error' },
    Unfulfillable: { label: 'Unfulfillable', color: 'error' },
    InvoiceUnconfirmed: { label: 'InvoiceUnconfirmed', color: 'warning' },
    PendingAvailability: { label: 'PendingAvailability', color: 'default' },
};

export interface OrderProps {
    sx?: SxProps;
    orders?: OrdersType[];
}

const AllOrders: React.FC<OrderProps> = ({ orders = [], sx }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredOrders, setFilteredOrders] = useState<OrdersType[]>(orders);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

    React.useEffect(() => {
        filterOrders();
    }, [orders, dateRange]);

    // Handle Pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = async () => {
        try {
            const response = await CustomFetch(API_URL + '/downloads/orders',
                {
                    method: 'POST',
                    body: JSON.stringify({ filename: "" }),
                }
            );
            if (!response.ok) {
                toast.error('Something went wrong! Please try again.');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'orders.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            toast.error('Something went wrong! Please try again.');
        }
    };

    // Filtering logic based on date range
    const filterOrders = () => {
        let filtered = orders;

        if (dateRange[0] && dateRange[1]) {
            filtered = filtered.filter(order => {
                const orderDate = dayjs(order.OrderDate);
                return orderDate.isAfter(dateRange[0]) && orderDate.isBefore(dateRange[1]);
            });
        }

        setFilteredOrders(filtered);
    };

    const handleFilterChange = (filterValue: string) => {
        const filtered = orders.filter(order =>
            order.Status.includes(filterValue) ||
            order.OrderId.includes(filterValue)
        );
        setFilteredOrders(filtered);
    };

    const paginatedOrders = filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Stack direction="row">
                <Card sx={{ flex: '1 1 auto', p: 2 }}>
                    <OutlinedInput
                        defaultValue=""
                        fullWidth
                        placeholder="Search Order by Order ID or Status"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: '500px' }}
                        onChange={(e) => handleFilterChange(e.target.value)}
                    />
                </Card>
                <Card sx={{ p: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            // sx={{ width: "50%"}}
                            localeText={{ start: "From Date", end: "To Date" }}
                            value={dateRange}
                            onChange={(newValue) => setDateRange(newValue)}
                        />
                    </LocalizationProvider>
                </Card>
            </Stack>

            <Card sx={sx}>
                <CardHeader
                    action={
                        <>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    localeText={{ start: "From Date", end: "To Date" }}
                                    value={dateRange}
                                    onChange={(newValue) => setDateRange(newValue)}
                                //   renderInput={(startProps, endProps) => (
                                //     <>
                                //       <input {...startProps.inputProps} placeholder="Start Date" />
                                //       <Box sx={{ mx: 2 }}>to</Box>
                                //       <input {...endProps.inputProps} placeholder="End Date" />
                                //     </>
                                //   )}
                                />
                            </LocalizationProvider> */}
                            <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                                Sync
                            </Button>
                            <Button variant='contained' size="small" onClick={handleDownload}>
                                Download
                            </Button>
                        </>
                    }
                // title="Orders"
                />
                <Divider />
                <Box sx={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ minWidth: 200 }}>Order</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Status</TableCell>
                                {/* <TableCell>Order Qty</TableCell> */}
                                <TableCell>Sales</TableCell>
                                <TableCell>Expense</TableCell>
                                <TableCell>Ads Spend</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Estimated Payout</TableCell>
                                <TableCell>Net Profit (Payout - Cost)</TableCell>
                                <TableCell>Info</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paginatedOrders.length > 0 ? (
                                    paginatedOrders.map((order) => {
                                        const { label, color } = statusMap[order.Status] ?? { label: 'Unknown', color: 'default' };

                                        return (
                                            <TableRow hover={true} key={order.OrderId}>
                                                <TableCell>
                                                    <Typography lineHeight={1.0} color={'text.secondary'}>{order.OrderId}</Typography>
                                                    <p>{dayjs(order.OrderDate).format('MMM D, YYYY')}</p>
                                                    {order.SellerOrderId && (<p>Seller Order Id: {order.SellerOrderId}</p>)}
                                                </TableCell>
                                                {
                                                    order.OrderItems.map((item, id) => (
                                                        <div key={id}>
                                                            <TableCell>
                                                                <Typography lineHeight={1.0}>
                                                                    <Box sx={{ textDecoration: 'none' }} component={Link} target='_blank' to={`https://www.amazon.in/${item.SellerSKU}/dp/${item.ASIN}`}>
                                                                        <Typography variant='caption' color={'text.secondary'}>
                                                                            {item.ASIN} - {item.SellerSKU}
                                                                        </Typography>
                                                                    </Box>
                                                                    <p>{item.Title}</p>
                                                                </Typography>
                                                            </TableCell>
                                                            {/* <TableCell>{item.Quantity}</TableCell> */}
                                                        </div>
                                                    ))
                                                }

                                                <TableCell><Chip color={color} label={label} /></TableCell>
                                                {/* <TableCell>{order.QTY}</TableCell> */}
                                                <TableCell>{order.IsReplacementOrder? 'Replacement' : order.OrderPrice }</TableCell>
                                                <TableCell>{parseFloat(order.TotalExpense).toFixed(2)}</TableCell>
                                                <TableCell>{order.AdSpend ? order.AdSpend.toFixed(2) : 0}</TableCell>
                                                {/* COST */}
                                                {/* <TableCell>
                                                    {
                                                        (() => {
                                                            let totalCost = 0;
                                                            order.OrderItems.forEach(item => {
                                                                totalCost += parseInt(item.Cost, 10);
                                                            });
                                                            return <div>{totalCost}</div>;
                                                        })()
                                                    }
                                                </TableCell> */}
                                                <TableCell>{order.Cost ? order.Cost.toFixed(2) : 0}</TableCell>
                                                <TableCell>{order.EstimatedPayout}</TableCell>
                                                <TableCell>
                                                    {
                                                        (() => {
                                                            let totalCost = 0;
                                                            order.OrderItems.forEach(item => {
                                                                totalCost += parseInt(item.Cost, 10);
                                                            });
                                                            return <div>{(parseFloat(order.EstimatedPayout) - totalCost).toFixed(2)}</div>;
                                                        })()
                                                    }
                                                </TableCell>
                                                <TableCell>Info</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </Box>
                <Divider />
                <TablePagination
                    component="div"
                    count={filteredOrders.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </Card>
        </>
    );
};

export default AllOrders;
import React from 'react';
import { useLocation } from 'react-router-dom';
import verifyService from './api/VerifyApi';
import { paths } from '../../../paths';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { VerifyAuthUrlAdAPI, VerifyAuthUrlSPAPI } from '../../../interfaces/Amazon/TokenObj';

const VerifyAmazonAuthCode: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // SP API params
  const spapi_oauth_code = queryParams.get('spapi_oauth_code');
  const state = queryParams.get('state');
  const selling_partner_id = queryParams.get('selling_partner_id');
  // Ad API params
  const code = queryParams.get('code');
  const scope = queryParams.get('scope');

  const authParamsSPAPI = {
    spapi_oauth_code,
    state,
    selling_partner_id
  }

  const authParamsAdAPI = {
    code,
    scope
  }

  // Function to handle Ads API authentication
  const handleAdAPI = async (authParamsAdAPI: VerifyAuthUrlAdAPI) => {
    if (!authParamsAdAPI.code) {
      toast.error('Invalid Ads API code');
      return;
    }
    try {
      const tokenObj = await verifyService.retrieveAmazonAdAPITokens(authParamsAdAPI.code);
      if (tokenObj['error']) {
        toast.error(`Ads integration failed: ${tokenObj['error']}`);
        navigate(paths.dashboard.overview, { replace: true });
      } else {
        toast.success('Ads API integrated successfully');
        navigate(paths.dashboard.overview, { replace: true });
      }
    } catch (error) {
      toast.error(`Something went wrong: ${error}`);
    }
  }

  // Function to handle SP API authentication
  const handleSPAPI = async (authParamsSPAPI: VerifyAuthUrlSPAPI) => {
    if (!authParamsSPAPI.spapi_oauth_code) {
      toast.error('Invalid SP API code');
      return;
    }

    try {
      const tokenObj = await verifyService.retrieveAmazonSPAPITokens(authParamsSPAPI.spapi_oauth_code);
      if (tokenObj['error']) {
        toast.error(`SP integration failed: ${tokenObj['error']}`);
        navigate(paths.dashboard.overview, { replace: true });
      } else {
        toast.success('SP API integrated successfully');
        navigate(paths.dashboard.overview, { replace: true });
      }
    } catch (error) {
      toast.error(`Something went wrong: ${error}`);
    }
  };

  React.useEffect(() => {
    // Check if Ads API parameters exist, then handle it
    if (authParamsAdAPI.code && authParamsAdAPI.scope) {
      handleAdAPI(authParamsAdAPI);
    }

    // Check if SP API parameters exist, then handle it
    if (authParamsSPAPI.spapi_oauth_code && authParamsSPAPI.selling_partner_id) {
      handleSPAPI(authParamsSPAPI);
    }
  }, [authParamsAdAPI, authParamsSPAPI, navigate]); // Add dependencies to avoid infinite loop

  return <></>; // Empty render since this component is for handling logic only

};

export default VerifyAmazonAuthCode;
import React from 'react'
import Layout from '../Layout'
import { Stack, Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { config } from '../../../config'
import { ppcType } from '../../../interfaces/PPC';
import PPCService from './api/PPC.api'
import PPCDataTable from '../../../components/Dashboard/PPC/PPCDataTable'
import LLineChart from '../../../components/Dashboard/PPC/Charts/LineChart'
import BBarChart from '../../../components/Dashboard/PPC/Charts/BarChart'
import SBarChart from '../../../components/Dashboard/PPC/Charts/SBarChart'
import PPieChart from '../../../components/Dashboard/PPC/Charts/PPieChart'
import SScatterPlot from '../../../components/Dashboard/PPC/Charts/SScatterPlot'
import AAreaChart from '../../../components/Dashboard/PPC/Charts/AAreaChart'
import BBubbleChart from '../../../components/Dashboard/PPC/Charts/BBubbleChart'
import KKPIMetrics from '../../../components/Dashboard/PPC/Charts/KKPIMetrics'

const PPC: React.FC = () => {

  const [ppcData, setPPCData] = React.useState<ppcType[]>([]);

  React.useEffect(() => {
    fetchPPCFromDB();
    // fetchPPCDemoData();
  }, [])

  const fetchPPCFromDB = async () => {
    let ppcFromDb = await PPCService.getPPCDataFromDB();
    if (ppcFromDb.data) setPPCData(ppcFromDb.data);
  }

  // const fetchPPCDemoData = async () => {
  //   let ppc = await PPCService.getPPCData();
  //   if (ppc) setPPCData(ppc);
  // }

  return (
    <PageContainer title={'PPC | Dashboard | ' + config.site.name} description='This is PPC page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Typography variant="h4">PPC</Typography>
          <PPCDataTable ppcData={ppcData} sx={{ height: '100%' }} />
        </Stack>
        <Stack spacing={3}>
          <LLineChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <BBarChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <SBarChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <PPieChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <SScatterPlot ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <AAreaChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <BBubbleChart ppcData={ppcData} />
        </Stack>
        <Stack spacing={3}>
          <KKPIMetrics ppcData={ppcData} />
        </Stack>
      </Layout>
    </PageContainer>
  )
}

export default PPC
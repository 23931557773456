export const tiersMonthly = [
    {
        title: 'Standard',
        price: '₹1,899',
        caption: 'billed monthly',
        description: [
            "3000 Orders per month",
            "150 Follow-up Emails",
            "4 Additional Seller Accounts",
            "1 Additional Users",
            "3 Automated Reports",
            // "No User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Start Now',
        buttonVariant: 'outlined',
        buttonColor: 'secondary',
    },
    {
        title: 'Professional',
        subheader: 'Recommended',
        price: '₹2,899',
        caption: 'billed monthly',
        description: [
            "6000 Orders per month",
            "6000 Follow-up Emails",
            "6 Additional Seller Accounts",
            "2 Additional Users",
            "6 Automated Reports",
            "User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
        buttonColor: 'primary',
    },
    {
        title: 'Enterprise',
        price: '₹3,899',
        caption: 'billed monthly',
        description: [
            "15000 Orders per month",
            "15000 Follow-up Emails",
            "8 Additional Seller Accounts",
            "4 Additional Users",
            "8 Automated Reports",
            "User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
        buttonColor: 'secondary',
    },
];

export const tiersYearly = [
    {
        title: 'Standard',
        price: '₹1,499',
        caption: '₹17,999 billed annually',
        description: [
            "3000 Orders per month",
            "150 Follow-up Emails",
            "4 Additional Seller Accounts",
            "1 Additional Users",
            "3 Automated Reports",
            // "No User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Start Now',
        buttonVariant: 'outlined',
        buttonColor: 'secondary',
    },
    {
        title: 'Professional',
        subheader: 'Recommended',
        price: '₹2,299',
        caption: '₹27,599 billed annually',
        description: [
            "6000 Orders per month",
            "6000 Follow-up Emails",
            "6 Additional Seller Accounts",
            "2 Additional Users",
            "6 Automated Reports",
            "User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
        buttonColor: 'primary',
    },
    {
        title: 'Enterprise',
        price: '₹3,099',
        caption: '₹36,999 billed annually',
        description: [
            "15000 Orders per month",
            "15000 Follow-up Emails",
            "8 Additional Seller Accounts",
            "4 Additional Users",
            "8 Automated Reports",
            "User Access Management",
            "Real-Time Profit Dashboard",
            "Listing Change Alerts",
            "PPC Optimization",
            "Inventory Management",
            "Refunds Lost Inventory",
            "LTV Dashboard",
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
        buttonColor: 'secondary',
    },
];
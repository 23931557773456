import React, { useState, FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import SignInService from "./api/SignInApi";
import { Helper } from "../../../utils/Helper";
import { toast } from "react-toastify";
import { Stack, Card, Typography, Link, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Layout from "../../../components/Auth/Layout";
import { paths } from "../../../paths";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UserPayload } from "../../../interfaces/UserPayload";

const SignIn: React.FC = () => {

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignIn = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      await SignInService.signIn(formData.email, formData.password);
      const userData: UserPayload | null = Helper.getCurrentUser();
      if (userData) {
        const destination = userData.role === 0 ? '/organizations' : '/dashboard';
        navigate(destination, { replace: true });
        toast.success(`Login Successful. Welcome ${userData.email}`);
      }
      setLoading(false);
    } catch (error) {
      toast.error('Login failed. Please try again');
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const renderForm = (
    <>
    <form onSubmit={handleSignIn}>
      <Stack spacing={3}>
        <TextField 
          name="email" 
          type="email"
          label="Email address" 
          value={formData.email}
          onChange={onChange}
          required
        />

        <TextField
          name="password"
          label="Password"
          value={formData.password}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
        <Link component={RouterLink} to={paths.auth.resetPassword} variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <Button
        fullWidth
        type="submit"
        variant="contained"
        // onClick={handleSignIn}
        disabled={loading}
      >
        Sign In
      </Button>
      </form>
    </>
  );

  return (
    <>
      <Layout>
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
            }}
          >
            <Typography variant="h4">Sign in</Typography>

            <Typography color="text.secondary" variant="body2" sx={{ mt: 2, mb: 5 }}>
              Don't have an account? 
              <Link component={RouterLink} to={paths.auth.signUp} variant="subtitle2" sx={{ ml: 0.5 }}>
                Sign up
              </Link>
            </Typography>

            {/* <Stack direction="row" spacing={2}>
              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              >
                <Box component="img" alt="Google" src="/assets/logo/logo-google.png" height={30} />
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              >
                <Box component="img" alt="Facebook" src="/assets/logo/logo-facebook.png" height={30} />
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              >
                <Box component="img" alt="Twitter" src="/assets/logo/logo-twitter-x.png" height={30} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            {renderForm}
          </Card>
        </Stack>
      </Layout>
    </>
  )
}

export default SignIn;
import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getAllOrders = async () => {
    const response = await CustomFetch(API_URL + '/orders', {method: 'GET'});
    return response.json();
}

// const getAllOrders = async () => {
//     const response = await CustomFetch(API_URL + '/amazon/orders', {method: 'GET'});
//     return response.json();
// }

// const getFinancialEvent = async () => {
//     const response = await CustomFetch(API_URL + '/amazon/finances', {method: 'GET'});
//     return response.json();
// }

const OrdersService = {
    getAllOrders,
    // getFinancialEvent
}

export default OrdersService;
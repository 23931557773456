import { NavItemConfig } from '../../../interfaces/NavItemConfig';
import { paths } from '../../../paths';

export const navItemsOrganization = [
  { key: 'overview', title: 'Overview', href: paths.organization.overview, icon: 'analytics' },
  { key: 'customers', title: 'Customers', href: paths.organization.customers, icon: 'people-outline' },
  { key: 'settings', title: 'Settings', href: paths.organization.settings, icon: 'settings' },
  { key: 'account', title: 'Account', href: paths.organization.account, icon: 'manage-account' },
  { key: 'error', title: 'Error', href: paths.errors.notFound, icon: 'error' },
] satisfies NavItemConfig[];

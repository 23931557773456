import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HubIcon from '@mui/icons-material/Hub';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const navIcons = {
    'donut-large': DonutLargeIcon,
    'analytics': AnalyticsIcon,
    'settings': SettingsIcon,
    'people-outline': PeopleOutlineIcon,
    'manage-account': ManageAccountsIcon,
    'hub': HubIcon,
    'error': ErrorIcon
} as Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">> >
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';

interface BBarChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

// Purpose: Visualize metrics such as total spends, clicks, impressions, etc., for each campaign, SKU, or time period.
// Data: Plot categories (e.g., campaignId, advertisedSku) on the x-axis and the corresponding metric (e.g., spends, clicks) on the y-axis.
// Chart Type: Vertical/Horizontal Bar Chart
// Example: Show the total spend for each SKU or Campaign.

const BBarChart: React.FC<BBarChartProps> = ({ ppcData }) => {
    
    const chartData = ppcData.reduce((acc, item) => {
        const existing = acc.find((data) => data.advertisedSku === item.advertisedSku);
        console.log('existing in barchart ', existing);
        if (existing) {
            // Accumulate the values for existing dates
            existing.spends += item.spend;
            existing.clicks += item.clicks;
        } else {
            // Create a new entry for the date
            acc.push({
                advertisedSku: item.advertisedSku,
                spends: item.spend,
                clicks: item.clicks,
            });
        }
        return acc;
    }, [] as { advertisedSku: string; spends: number; clicks: number }[]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="advertisedSku" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="spends" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BBarChart;
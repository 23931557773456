import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

interface Data {
  title: string
  description: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

export const advantageData: Data[] = [
  {
    title: 'Customer Support',
    description: 'Our support team is available on call & live chat 7 days a week, throughout the year.',
    Icon: SupportAgentIcon,
  },
  {
    title: 'Dedicated Account Manager',
    description: 'You will get a dedicated account manager to help you with everything related to our products & services.',
    Icon: PersonOutlinedIcon,
  },
  {
    title: 'Flexible Study Time',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    Icon: SupportAgentIcon,
  },
  {
    title: 'Integrations',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    Icon: IntegrationInstructionsOutlinedIcon,
  },
]

import { Box, Container, Grid, Typography } from '@mui/material'
import { advantageData } from './AdvantageData'

const HomeFeature: React.FC = () => {
  return (
    <Box id="feature" sx={{ py: { xs: 10, md: 14 }, backgroundColor: 'background.paper' }}>
      <Container>
        <Grid container spacing={3}>

          {/* <Grid item xs={12} md={5}>
            <Box sx={{ position: 'relative' }}>
              <img src="/assets/section/section-bg-dark.png" width={"100%"} alt="Feature img" />
              </Box>
          </Grid> */}

          <Grid item >
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: { xs: 40, md: 50 },
                ml: { xs: 0, md: 4 },
                mt: 2,
                mb: 3,
                lineHeight: 1,
                fontWeight: 'bold',
              }}
            >
              The{' '}
              <Typography
                component="mark"
                sx={{
                  position: 'relative',
                  color: 'primary.main',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  backgroundColor: 'unset',
                }}
              >
                Virtacc{' '}
              </Typography>
              Advantages
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 2, ml: { xs: 0, md: 4 } }}>
              Set the way of learning according to your wishes with some of the benefits that you get us, so you on
              enjoy the lessons that we provide.
            </Typography>
            <Grid container spacing={2} sx={{ ml: { xs: -2, md: 2 } }}>
              {advantageData.map(({ title, description, Icon }, index) => (
                <Grid key={String(index)} item xs={12} md={6}>
                  <Box sx={{ px: 2, py: 1.5, boxShadow: 20, borderRadius: 4, display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={1} direction={{ xs: 'column', md: 'row' }} alignItems="center">
                      <Grid item>
                        <Box sx={{
                          mr: 1,
                          '& svg': { fontSize: 70, },
                          height: 100, width: 100,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }} >
                          <Icon />
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box sx={{ display: 'flex', flex: 1, textAlign: { xs: 'center', md: 'left' }, flexDirection: 'column' }}>
                          <Typography variant="h4" sx={{ mb: 1 }}>
                            {title}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary' }} variant="subtitle1">
                            {description}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeFeature

import React, { useState } from 'react';
import { 
    Card, CardHeader, Button, Divider, Box, Table, TableHead, TableRow, TableCell, 
    TableBody, Chip, TablePagination, ChipProps, SxProps, OutlinedInput, InputAdornment 
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { ppcType } from '../../../interfaces/PPC';
import dayjs from 'dayjs';

const API_URL = process.env.REACT_APP_API_URL;

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
    PAUSED: { label: 'Inactive', color: 'default' },
    ENABLED: { label: 'Active', color: 'success' },
};

export interface PPCProps {
    sx?: SxProps;
    ppcData?: ppcType[];
}

const PPCDataTable: React.FC<PPCProps> = ({ ppcData = [], sx }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredPPCData, setFilteredPPCData] = useState<ppcType[]>(ppcData);

    // Filtering logic based on date range
    const filterPPCData = () => {
        let filtered = ppcData;
        setFilteredPPCData(filtered);
    };

    React.useEffect(() => {
        filterPPCData();
    }, [ppcData]);

    // Handle Pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = async () => {
        try {
            toast.info('Clicked on Download Button')
            // toast.loading('loading ...')
            // toast.dismiss()
            // toast.warn('warn');
            // toast.warning('warning')
        } catch (err) {
            toast.error('Something went wrong! Please try again.');
        }
    };

    const handleFilterChange = (filterValue: string) => {
        const filtered = ppcData.filter(ppc =>
            ppc.advertisedSku.includes(filterValue) ||
            ppc.advertisedAsin.includes(filterValue) || 
            ppc.campaignId.toString() === filterValue ||
            ppc.date.includes(filterValue)
        );
        setFilteredPPCData(filtered);
    };

    const paginatedPPCData = filteredPPCData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Card sx={{ p: 2 }}>
                <OutlinedInput
                    defaultValue=""
                    fullWidth
                    placeholder="Search by SKU, ASIN, Campaign Id, Date (YYYY-MM-DD)"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    sx={{ maxWidth: '500px' }}
                    onChange={(e) => handleFilterChange(e.target.value)}
                />
            </Card>
            <Card sx={sx}>
                <CardHeader
                    action={
                        <>
                            <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                                Sync
                            </Button>
                            <Button variant='contained' size="small" onClick={handleDownload}>
                                Download
                            </Button>
                        </>
                    }
                // title="Orders"
                />
                <Divider />
                <Box sx={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell sx={{ minWidth: 200 }}>Order Number</TableCell> */}
                                <TableCell>Date</TableCell>
                                <TableCell>Campaign Id</TableCell>
                                <TableCell>Campaign Status</TableCell>
                                <TableCell>ROAS Clicks 7d</TableCell>
                                <TableCell>CTR</TableCell>
                                <TableCell>Spend</TableCell>
                                <TableCell>Sales 30d</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>ASIN</TableCell>
                                <TableCell>ACOS CLicks 7d</TableCell>
                                <TableCell>Impresstions</TableCell>
                                <TableCell>Clicks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedPPCData.length > 0 ? (
                                paginatedPPCData.map((ppc, index) => {
                                    const { label, color } = statusMap[ppc.campaignStatus] ?? { label: 'Unknown', color: 'default' };
                                    return (
                                        <TableRow hover={true} key={index}>
                                            <TableCell>{dayjs(ppc.date).format('MMM D, YYYY')}</TableCell>
                                            <TableCell>{ppc.campaignId}</TableCell>
                                            <TableCell><Chip color={color} label={label} /></TableCell>
                                            <TableCell>{ppc.roasClicks7d}</TableCell>
                                            <TableCell>{ppc.clickThroughRate}</TableCell>
                                            <TableCell>{ppc.spend}</TableCell>
                                            <TableCell>{ppc.sales30d}</TableCell>
                                            <TableCell>{ppc.advertisedSku}</TableCell>
                                            <TableCell>{ppc.advertisedAsin}</TableCell>
                                            <TableCell>{ppc.acosClicks7d}</TableCell>
                                            <TableCell>{ppc.impressions}</TableCell>
                                            <TableCell>{ppc.clicks}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
                <Divider />
                <TablePagination
                    component="div"
                    count={filteredPPCData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </Card>
        </>
    );
};

export default PPCDataTable;
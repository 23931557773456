import React, { useEffect, useState } from "react";
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import { Button, IconButton, Modal } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import ChannelsTable from '../../../components/Dashboard/Settings/Integrations/ChannelsTable';
import IntegrationCard, { Integration } from '../../../components/Dashboard/Settings/Integrations/IntegrationCard';
import ConnectIntegrationForm from '../../../components/Dashboard/Settings/Integrations/ConnectIntegrationForm';
import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { IntegrationData } from "../../../interfaces/Integration";
import IntegrationService from "../../../components/Dashboard/Settings/Integrations/api/Integration.api";

const integrations = [
  {
    id: 'INTEG-001',
    title: 'Amazon',
    description: 'Once connected, you can automate your product review requests, track your financial data, monitor your products and detect hijackers.',
    logo: '/assets/logo/logo-amazon.png',
    link: 'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.9840f9db-475a-4925-85b5-a7cb7a8d4912'
  },
  {
    id: 'INTEG-002',
    title: 'Amazon Ads',
    description: 'Once connected, you can track your PPC spend, examine PPC campaigns’ performance and automate your Amazon PPC campaigns.',
    logo: '/assets/logo/logo-amazon.png',
    link: 'https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.30f67e0177d9403495a4c63c65de2549&scope=advertising::campaign_management&response_type=code&redirect_uri=https://virtacc.com/auth/verify'
  },
  {
    id: 'INTEG-003',
    title: 'Flipkart',
    description: '',
    logo: '/assets/logo/logo-flipkart.png',
    link: ''
  },
  {
    id: 'INTEG-004',
    title: 'Myntra',
    description: '',
    logo: '/assets/logo/logo-myntra.png',
    link: ''
  },
  {
    id: 'INTEG-005',
    title: 'Meesho',
    description: '',
    logo: '/assets/logo/logo-meesho.png',
    link: ''
  },
  {
    id: 'INTEG-006',
    title: 'Jio Mart',
    description: '',
    logo: '/assets/logo/logo-jiomart.png',
    link: ''
  },
  {
    id: 'INTEG-007',
    title: 'Snapdeal',
    description: '',
    logo: '/assets/logo/logo-snapdeal.png',
    link: ''
  },
] satisfies Integration[];


const Integrations: React.FC = () => {

  const [integrationsData, setIntegrationsData] = useState<IntegrationData[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getIntegrations();
  }, []);

  const getIntegrations = async () => {
    let integrationsData = await IntegrationService.getIntegrations();
    if (integrationsData.data) setIntegrationsData(integrationsData.data);
  };

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleFormSuccess = () => {
    handleModalClose(); // Close the modal
    getIntegrations();  // Refresh the organization data
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid var(--mui-palette-primary-main)',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };

  const renderModal = (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-add-integration"
      aria-describedby="modal-add-integration-description"
    >
      <Stack sx={style}>
        <Stack direction="row" spacing={3}>

          <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
            <Typography sx={{ mb: 3 }} variant="h5">Connet to Integration</Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{ position: 'absolute', right: 20 }}
          >
            <Close />
          </IconButton>

        </Stack>
        <ConnectIntegrationForm onSuccess={handleFormSuccess} />
      </Stack>
    </Modal>
  )

  return (
    <PageContainer title={'Integrations | Dashboard | ' + config.site.name} description='This is Integrations page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Stack direction="row" spacing={3} >
            <Stack sx={{ flex: '1 1 auto' }} spacing={1} >
              <Typography variant="h4">Integrations</Typography>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Button color="inherit" startIcon={<DownloadIcon />}>
                  Import
                </Button>
              </Stack>
            </Stack>
            <div>
              <Button onClick={handleModalOpen} startIcon={<AddIcon />} variant="contained">
                Add
              </Button>
            </div>
            {renderModal}
          </Stack>
          <ChannelsTable
            channels={integrationsData}
            sx={{ height: '100%' }}
          />
          <Grid container spacing={3}>
            {integrations.map((integration) => (
              <Grid key={integration.id} lg={4} md={6} xs={12}>
                <IntegrationCard integration={integration} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Layout>
    </PageContainer>
  );
}

export default Integrations;

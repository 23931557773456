import { Testimonial } from "../../../interfaces/Testimonial";

export const data: Array<Testimonial> = [
  {
    id: 1,
    title: 'Detailed learning materials',
    content:
      'Virtacc improved our inventory visibility and expedited kit processing time, ensuring on-time delivery to our professionals.',
    user: {
      id: 1,
      name: 'Sonu Kumar',
      professional: 'Vice President – Operations',
      photo: '1.jpg',
    },
  },
  {
    id: 2,
    title: 'Best Quality Online Course!',
    content:
      'With Virtacc handling our warehouse & multichannel operations, we can now focus solely on brand development. A game changer!',
    user: {
      id: 1,
      name: 'Rishu Taneja',
      professional: 'VP, Beauty & Wellness',
      photo: '2.jpg',
    },
  },
  {
    id: 3,
    title: 'Very complete class',
    content:
      'Virtacc’s dropship and vendor management solutions are driving our business growth. Highly recommend it to other e-commerce businesses.',
    user: {
      id: 1,
      name: 'Ashish Bansal',
      professional: 'Chief Digital Officer',
      photo: '3.jpg',
    },
  },
  {
    id: 4,
    title: 'Great Quality!',
    content:
      'Having Unicommerce as our technology partner for order management has been instrumental in building the supply chain efficiencies',
    user: {
      id: 1,
      name: 'Tridevi Taneja',
      professional: 'Vice President – Operations',
      photo: '4.jpg',
    },
  },
]

'use client';

import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { navItems } from './config';
import { navIcons } from './NavIcons';
import { Logo } from '../../Core/Logo';
import { isNavItemActive } from '../../../utils/IsNavItemActive';
import { paths } from '../../../paths';
import { Helper } from '../../../utils/Helper';
import { NavItemConfig } from '../../../interfaces/NavItemConfig';
import { UserPayload } from '../../../interfaces/UserPayload';

export interface MobileNavProps {
    onClose?: () => void;
    open?: boolean;
    items?: NavItemConfig[];
}

const MobileNav: React.FC<MobileNavProps> = ({ open, onClose }) => {
    const location = useLocation();
    const [user, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: '' });
    React.useEffect(() => {
        const User = Helper.getCurrentUser();
        setUser(User);
    }, []);

    const [openSubMenu, setOpenSubMenu] = React.useState<string | null>(null);

    const handleSubMenuToggle = (key: string) => {
        setOpenSubMenu(openSubMenu === key ? null : key);
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    '--MobileNav-background': 'var(--mui-palette-neutral-950)',
                    '--MobileNav-color': 'var(--mui-palette-common-white)',
                    '--NavItem-color': 'var(--mui-palette-neutral-300)',
                    '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
                    '--NavItem-active-background': 'var(--mui-palette-primary-main)',
                    '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
                    '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
                    '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
                    '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
                    '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
                    bgcolor: 'var(--MobileNav-background)',
                    color: 'var(--MobileNav-color)',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    scrollbarWidth: 'none',
                    width: 'var(--MobileNav-width)',
                    zIndex: 'var(--MobileNav-zIndex)',
                    '&::-webkit-scrollbar': { display: 'none' },
                },
            }}
            onClose={onClose}
            open={open}
        >
            <Stack spacing={2} sx={{ p: 3 }}>
                <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
                    <Logo color="light" height={32} width={122} />
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'var(--mui-palette-neutral-950)',
                        border: '1px solid var(--mui-palette-neutral-700)',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        display: 'flex',
                        p: '4px 12px',
                    }}
                >
                    <Box sx={{ flex: '1 1 auto' }}>
                        <Typography color="var(--mui-palette-neutral-400)" variant="body2">
                            Organization
                        </Typography>
                        <Typography color="inherit" variant="subtitle1">
                            {user.organization || '-'}
                        </Typography>
                    </Box>
                    <UnfoldMoreIcon />
                </Box>
            </Stack>
            <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
            <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
                {renderNavItems({ pathname: location.pathname, items: navItems, openSubMenu, handleSubMenuToggle })}
            </Box>
            <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
            <Stack spacing={2} sx={{ p: '12px' }}>
                {/* Add additional content */}
            </Stack>
        </Drawer>
    );
};

const renderNavItems: React.FC<{
    items: NavItemConfig[];
    pathname: string;
    openSubMenu: string | null;
    handleSubMenuToggle: (key: string) => void;
}> = ({ items, pathname, openSubMenu, handleSubMenuToggle }) => {
    return (
        <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
            {items.map((item) => (
                <NavItem key={item.key} item={item} pathname={pathname} openSubMenu={openSubMenu} handleSubMenuToggle={handleSubMenuToggle} />
            ))}
        </Stack>
    );
};

interface NavItemProps {
    item: NavItemConfig;
    pathname: string;
    openSubMenu: string | null;
    handleSubMenuToggle: (key: string) => void;
}

const NavItem: React.FC<NavItemProps> = ({ item, pathname, openSubMenu, handleSubMenuToggle }) => {
    const active = isNavItemActive({ href: item.href, matcher: item.matcher, pathname });
    const Icon = item.icon ? navIcons[item.icon] : null;

    const handleClick = () => {
        if (item.items) handleSubMenuToggle(item.key);
    };

    return (
        <li>
            <Box
                component={item.external ? 'a' : RouterLink}
                to={item.href || '#'}
                target={item.external ? '_blank' : undefined}
                rel={item.external ? 'noreferrer' : undefined}
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    color: 'var(--NavItem-color)',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: 1,
                    p: '6px 16px',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    ...(item.disabled && {
                        bgcolor: 'var(--NavItem-disabled-background)',
                        color: 'var(--NavItem-disabled-color)',
                        cursor: 'not-allowed',
                    }),
                    ...(active && { bgcolor: 'var(--NavItem-active-background)', color: 'var(--NavItem-active-color)' }),
                }}
                onClick={handleClick}
            >
                {Icon && (
                    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Icon
                            fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
                        />
                    </Box>
                )}
                <Box sx={{ flex: '1 1 auto' }}>
                    <Typography
                        component="span"
                        sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
                    >
                        {item.title}
                    </Typography>
                </Box>
                {item.items && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {openSubMenu === item.key ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </Box>
                )}
            </Box>
            {item.items && (
                <Collapse in={openSubMenu === item.key} timeout="auto" unmountOnExit>
                    <Box sx={{ pl: 4 }}>
                        {renderNavItems({ items: item.items, pathname, openSubMenu, handleSubMenuToggle })}
                    </Box>
                </Collapse>
            )}
        </li>
    );
};

export default MobileNav;
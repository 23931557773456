// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { ppcType } from '../../../../interfaces/PPC';

// interface LLineChartProps {
//     ppcData: ppcType[]; // Array of ppcType data to pass to the chart
// }

// // Purpose: Track spends, clicks, or other metrics over time.
// // Data: Plot date on the x-axis and spends, clicks on the y-axis. If you have multiple campaigns or SKUs, you could either show them in separate lines or as grouped lines.
// // Chart Type: Line Chart
// // Example: A time series of your total spends or clicks over several days.

// const LLineChart: React.FC<LLineChartProps> = ({ ppcData }) => {

//     const chartData = ppcData.reduce((acc, item) => {
//         const existing = acc.find((data) => data.date === item.date);
//         if (existing) {
//             // Accumulate the values for existing dates
//             existing.spend += item.spend;
//             existing.clicks += item.clicks;
//         } else {
//             // Create a new entry for the date
//             acc.push({
//                 date: item.date,
//                 spend: item.spend,
//                 clicks: item.clicks,
//             });
//         }
//         return acc;
//     }, [] as { date: string; spend: number; clicks: number }[]);

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <LineChart data={chartData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="date" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Line type="monotone" dataKey="spends" stroke="#8884d8" />
//                 <Line type="monotone" dataKey="clicks" stroke="#82ca9d" />
//             </LineChart>
//         </ResponsiveContainer>
//     );
// };

// export default LLineChart;

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';
import dayjs from 'dayjs'; // Import dayjs for date formatting

interface LLineChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const formatNumber = (number: number) => {
    return parseFloat((number).toPrecision(2))
}

const LLineChart: React.FC<LLineChartProps> = ({ ppcData }) => {

    // Reduce the ppcData to combine values for each date
    const chartData = ppcData.reduce((acc, item) => {
        const formattedDate = dayjs(item.date).format('DD MMM'); // Format date as "YYYY-MM-DD"
        const existing = acc.find((data) => data.date === formattedDate);
        if (existing) {
            // Accumulate values for existing dates
            existing.spend += formatNumber(item.spend);
            existing.clicks += item.clicks;
            existing.sales7d += formatNumber(item.sales7d);
            // existing.purchases7d += item.purchases7d;
            existing.impressions += item.impressions;
            existing.roasClicks14d += formatNumber(item.roasClicks14d);
            existing.costPerClick += formatNumber(item.costPerClick);
        } else {
            // Create a new entry for the date
            acc.push({
                date: formattedDate,
                spend: formatNumber(item.spend),
                clicks: item.clicks,
                sales7d: formatNumber(item.sales7d),
                // purchases7d: item.purchases7d,
                impressions: item.impressions,
                roasClicks14d: formatNumber(item.roasClicks14d),
                costPerClick: formatNumber(item.costPerClick),
            });
        }
        return acc;
    }, [] as { 
        date: string; 
        spend: number; 
        clicks: number; 
        sales7d: number; 
        // purchases7d: number; 
        impressions: number; 
        roasClicks14d: number; 
        costPerClick: number;
    }[]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* Add different lines for various metrics */}
                <Line type="monotone" dataKey="spend" stroke="#8884d8" />
                <Line type="monotone" dataKey="clicks" stroke="#82ca9d" />
                <Line type="monotone" dataKey="sales7d" stroke="#ff7300" />
                {/* <Line type="monotone" dataKey="purchases7d" stroke="#ff0000" /> */}
                <Line type="monotone" dataKey="impressions" stroke="#00bcd4" />
                <Line type="monotone" dataKey="roasClicks14d" stroke="#9c27b0" />
                <Line type="monotone" dataKey="costPerClick" stroke="#009688" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LLineChart;

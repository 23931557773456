import React from "react";

import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';

interface PPieChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Purpose: Show the proportion of a metric (like spends or clicks) across different categories (e.g., advertisedSku, campaignId).
// Data: Categories (e.g., SKU or Campaign) on the chart slices and corresponding metrics (e.g., spends, clicks).
// Chart Type: Pie Chart
// Example: Pie chart showing the proportion of total spends by advertisedSku.

const PPieChart: React.FC<PPieChartProps> = ({ ppcData }) => {

    const chartData = ppcData.reduce((acc, item) => {
        const existing = acc.find((data) => data.advertisedSku === item.advertisedSku);
        if (existing) {
            // Accumulate the values for existing dates
            existing.spends += item.spend;
        } else {
            // Create a new entry for the date
            acc.push({
                advertisedSku: item.advertisedSku,
                spends: item.spend,
            });
        }
        return acc;
    }, [] as { advertisedSku: string; spends: number; }[]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie data={chartData} dataKey="spends" nameKey="advertisedSku" outerRadius={100} fill="#8884d8" label>
                    {chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PPieChart;